import React from 'react'
import PageContainer from '../components/PageContainer/PageContainer'
import Title from '../components/Title/Title'
import Layout from '../Layouts/Layout'

const NotFoundPage = () => (
  <Layout>
    <Title>NOT FOUND</Title>
    <PageContainer>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </PageContainer>
  </Layout>
)

export default NotFoundPage
